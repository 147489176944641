<template>
  <v-dialog
      v-model="dialog"
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
               v-on="on" color="secondary">
        <v-icon
            left
        >
          mdi-pencil
        </v-icon>
        Daten bearbeiten
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="primary">
        Daten nachtragen
      </v-card-title>
      <v-card-subtitle class="mt-4">Die fehlenden Daten sind eventuell aus dem Download auszulesen.</v-card-subtitle>
      <v-card-text >
        <v-form
            :lazy-validation="lazy"
            class="form"
            ref="form"
            v-model="valid"
        >
        <v-col cols="12">
          <v-text-field
              :label="inputLabels.name"
              required
              :rules="nameRules"
              v-model="name"
          ></v-text-field>

        </v-col>
        <v-col cols="12">
          <v-text-field
              v-model="email"
              :label="inputLabels.email"
              :rules="emailRules"
              required
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
              v-model="phone"
              label="Telefonnummer des Kunden"
          ></v-text-field>
        </v-col>
          <v-col cols="12">
        <v-text-field
            label="Versicherungsnummer"
            :rules="policyNumberRules"
            :counter="maxCounterPolicyNumber"
            v-model="policyNumber"
        ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              label="als Servicepauschale abrechnen"
              v-model="lawyerCouldNotHelp"
            ></v-checkbox>
          </v-col>
        </v-form>
      </v-card-text>


      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="error"
            @click="dialog = false"
        >
          Abbrechen
        </v-btn>
        <v-btn
            color="primary"
            :disabled="!valid"
            @click="closeDialog()"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {getAragURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";

export default {
  name: "AddToArag",
  props: {
    aragData: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    lawyerCouldNotHelp: false,
    lazy: false,
    valid: true,
    dialog: false,
    phone: '+49',
    free: false,
    email: '',
    checkNumbers: ["1100","1101","1000","1001"],
    name: '',
    policyNumber: '',
    maxCounterPolicyNumber: 8,
  }),
  methods: {
    closeDialog(){
      this.sendData();
    },
    async sendData() {
      // set Loading true
      this.isLoading = true;

      // init Request Data
      const formData = {
        "customerMailAddress": this.email,
        "customerPhoneNumber": this.phone,
        "contractNumber": this.policyNumber,
        "customerName": this.name,
        "lawyerCouldNotHelpCustomer": this.lawyerCouldNotHelp,
      };
      // console.log("data", formData)
      //  if Session isValid
      await sessionHandler();

      // send add to Database request
      await this.$http
          .patch(
              getAragURL(true)  + "/" + this.aragData.id,
              formData
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.dialog = false
              this.$emit("getCases")
            } else {
              showAlert(i18n.t('error.api.undefined') + "ARAG-sD1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              } else {
                showAlert(i18n.t('error.api.undefined') + "AdvocardPrivate-sD2", "error");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "ARAG-sD2", "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
    },
  },
  computed: {
    inputLabels() {
      return JSON.parse(JSON.stringify(this.$t('insurance.Arag.inputField')));
    },
    errorMessage() {
      return JSON.parse(JSON.stringify(this.$t('error.validation')));
    },
    policyNumberRules() {
      if (this.policyNumber.length > 0) {
        return [
          v => /^\d+$/.test(v) || this.errorMessage["onlyNumbers"],
          v => (-1 !== this.checkNumbers.findIndex(element => {
            if(this.policyNumber?.length > 3){
              if (element.includes(this.policyNumber.substring(0,4))) {
                return true;
              }
            }
          }) ? v?.length === 14 : v?.length === 8),
          v => v !== "00000000",
        ]
      } else {
        return [  ]
      }
    },
    nameRules() {
      return [
        v => !!v || this.errorMessage.required,
      ]
    },
    emailRules() {
      return [
        v => /^\S+@\S+\.\S+$/.test(v) || this.errorMessage["validEmail"],
      ]
    },
  },
  mounted() {
    if(this.aragData.customerPhoneNumber){
      this.phone = this.aragData.customerPhoneNumber.length > 0 ? this.aragData.customerPhoneNumber : "+49" ;
    }
    this.email = this.aragData.customerMailAddress ? this.aragData.customerMailAddress : "";
    this.name = this.aragData.customerName ?  this.aragData.customerName: "";
    this.policyNumber = this.aragData.contractNumber ?this.aragData.contractNumber : "";
    this.claimNumber = this.aragData.claimNumber ? this.aragData.claimNumber : "";
    this.lawyerCouldNotHelp = this.aragData.lawyerCouldNotHelpCustomer ? this.aragData.lawyerCouldNotHelpCustomer : false;
  },
  watch: {
    policyNumber: {
      handler() {
        this.maxCounterPolicyNumber = (-1 !== this.checkNumbers.findIndex(element => {
          if(this.policyNumber.length > 3){
            if (element.includes(this.policyNumber.substring(0,4))) {
              return true;
            }
          }
        }) ? 14 : 8);
      }
    }
  }
}
</script>

<style scoped>

</style>
