<template>
  <v-card>
    <v-card-title>
      {{ openCase.customerName }}
      {{ openCase.customerLastname }}
      <v-spacer></v-spacer>
      <customer-mail-dialog v-bind:controllerURL="controllerURL" v-bind:currentCase="openCase" v-on:getCases="getCases()"/>
      <v-btn :href="'tel:' + openCase.customerPhoneNumber" color="primary" icon>
        <v-icon>mdi-phone</v-icon>
      </v-btn>
      <resent-upload-link v-bind:case-id="openCase.id" v-bind:controller-u-r-l="controllerURL"/>
    </v-card-title>
   <v-card-text>
     <v-card color="primary" flat>
       <v-card-title>
         Fragestellung: <view-more v-bind:text="openCase.notes" v-bind:cutting-count="200" />
       </v-card-title>
       <v-card-subtitle>
         <span v-if="openCase.customerMailAddress">{{ openCase.customerMailAddress }}</span><br v-if="openCase.customerMailAddress" />
         <span v-if="openCase.customerPhoneNumber">{{ openCase.customerPhoneNumber }}</span><br v-if="openCase.customerPhoneNumber" />
         <span v-if="openCase.claimNumber">Schadennummer: {{ openCase.claimNumber }}</span><br v-if="openCase.claimNumber" />
         <span v-if="openCase.contractNumber" >Versicherungsnummer:  {{ openCase.contractNumber }}</span>
       </v-card-subtitle>
       <div v-if="openCase.comment">
         <v-card-subtitle>Kommentar</v-card-subtitle>
         <v-card-text>
           <view-more v-bind:text="openCase.comment" />
         </v-card-text>
       </div>
       <div v-if="openCase.record">
         <v-card-subtitle>Sachverhalt</v-card-subtitle>
         <v-card-text>
           <view-more v-bind:text="openCase.record" />
         </v-card-text>
       </div>
     </v-card>
   </v-card-text>
    <div>
      <v-card-text>
        <v-card flat color="primary">
          <v-card-title>
            Erstellt am {{ openCase.createdAt }} von {{ openCase.createdBy }}
          </v-card-title>
        </v-card>
      </v-card-text>
      <v-card-text v-if="openCase.lawyerCouldNotHelpCustomer">
        <v-card flat color="primary">
          <v-card-title>
            Wird als Servicepauschale geschlossen
          </v-card-title>
        </v-card>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-textarea v-on:input="saveToLocalStorage()" v-model="closingReport" :counter="1000" color="primary" label="Sachverhaltsbeschreibung" outlined></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn color="primary" :disabled="closingReport.length > 1000" @click="save(openCase.id)">    <v-icon
                left
            >
              mdi-content-save
            </v-icon> Beschreibung Speichern</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-actions>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="auto" v-if="allowDataEdit">
            <add-to-arag v-bind:arag-data="openCase" v-on:getCases="getCases()" />
          </v-col>
          <v-col cols="auto">
            <ChevalierDialog v-bind:data-type-name="dataTypeName" v-bind:data-type="dataType" v-bind:controller-u-r-l="controllerURL" v-bind:open-case="openCase" />
          </v-col>
          <v-col cols="auto">
            <v-dialog
                v-model="closeDialog"
                max-width="600"
                transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <!-- todo check if works
               1000 -->
                    <v-btn
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="closingReport.length === 0 || closingReport.length > 1000"
                    >
                      <v-icon
                          left
                      >
                        mdi-lock
                      </v-icon>
                      Fall schließen
                    </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar
                      color="primary"
                      dark
                  >Fall schließen?
                  </v-toolbar>
                  <v-card-text>
                    <p>Wollen Sie den Fall wirklich schließen?</p>
                    <p>Die Dokumente werden dadurch gelöscht und der Link des Kunden wird ungültig!</p>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn
                        text
                        color="error"
                        @click="dialog.value = false"
                    >Abbruch
                    </v-btn>
                    <v-btn color="secondary" v-if="checkIfSpAvailable()" @click="closeAsSP(openCase.id)">Fall schließen als Servicepauschale</v-btn>
                    <v-btn color="primary" @click="close(openCase.id)">{{ openCase.lawyerCouldNotHelpCustomer ? "Servicepauschale schließen" : "Schließen"}}</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-divider></v-divider>
      <file-downloads v-bind:dataTypeName="dataTypeName" v-bind:id="openCase.caseUUID" v-bind:controllerURL="controllerURL" v-bind:dataType="dataType" />
    </div>
  </v-card>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {getAragURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";
import AddToArag from "@/components/dialogs/AddToArag";
import FileDownloads from "@/components/Case/FileDownloads";
import CustomerMailDialog from "@/components/Case/CustomerMailDialog";
import ViewMore from "@/components/ViewMore";
import ResentUploadLink from "@/components/dialogs/ResentUploadLink";
import ChevalierDialog from "@/components/Case/ChevalierDialog.vue";

export default {
  name: "OpenCaseCard",
  components: {ChevalierDialog, ResentUploadLink, ViewMore, CustomerMailDialog, AddToArag, FileDownloads},
  props: {
    openCase: {
      required: true
    },
    controllerURL: {
      required: true
    },
    dataType: {
      required: true,
    },
    dataTypeName: {
      required: true
    },
    allowDataEdit: {
      required: true
    },
  },
  data: () => ({
    closingReport: "",
    submitReport: "",
    submitDialog: false,
    dialog: false,
    closeDialog: false,
    tooltip: false
  }),
  mounted() {
    console.log(this.openCase)
    this.closingReport = this.openCase.closingReport ? this.openCase.closingReport : "";
    if (this.closingReport === "") {
      if (localStorage.getItem('OpenCaseCardCase' + this.openCase.id)) {
        this.recoverData()
      }
    }
    this.closingCustomerMessage = this.openCase.closingCustomerMessage ? this.openCase.closingCustomerMessage : ""
  },
  methods: {

    clearRecoverData() {
      localStorage.setItem('OpenCaseCardCase' + this.openCase.id, "")
    },
    recoverData() {
      this.closingReport = localStorage.getItem('OpenCaseCardCase' + this.openCase.id)
    },
    saveToLocalStorage(){
      localStorage.setItem('OpenCaseCardCase' + this.openCase.id, this.closingReport)
    },
    async close(openCaseId) {
      await this.save(openCaseId)
          await sessionHandler();
          await this.$http
              .patch(
                  this.controllerURL + "/" + "Own" + "/" + openCaseId,
                  {
                    "closingReport": this.openCase.closingReport,
                    "closed": !this.openCase.closed
                  }
                  , {
                    mode: requestMode(),
                    headers: {
                      'Accept': 'application/json',
                      'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                    },
                  }
              )
              .then((response) => {
                if (response.status === 200) {
                  this.clearRecoverData();
                  this.getCases();
                }
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.status === 401) {
                    showAlert(i18n.t('warning.unauthorised'), "warning");
                  }
                } else {
                  showAlert(i18n.t('error.api.undefined') + "Calendar-gE2", "error");
                }
              })
              .finally(() => {
                this.isLoadingBill = false;
              })
      },
    async sendData(openCaseId) {
      // init Request Data
      const formData = {
        "lawyerCouldNotHelpCustomer": "true",
      };
      //  if Session isValid
      await sessionHandler();

      // send add to Database request
      await this.$http
          .patch(
              getAragURL(true)  + "/" + openCaseId,
              formData
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              showAlert("Der Fall wurde als Servicepauschale geschlossen", "info");
            } else {
              showAlert(i18n.t('error.api.undefined') + "ARAG-sD1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              } else {
                showAlert(i18n.t('error.api.undefined') + "AdvocardPrivate-sD2", "error");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "ARAG-sD2", "error");
            }
          })
    },
    async closeAsSP(openCaseId) {
      await this.sendData(openCaseId);
      await this.close(openCaseId);
    },
    checkIfSpAvailable() {
      if (this.openCase.aragDataType){
        return !this.openCase.lawyerCouldNotHelpCustomer
      }
      return false
    },
    async save(openCaseId) {
      await sessionHandler();
      await this.$http
          .patch(
              this.controllerURL + "/" + "Own" + "/" + openCaseId,
              {
                "closingReport": this.closingReport,
                "closed": this.openCase.closed
              }
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.clearRecoverData();
              this.getCases();
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gE2", "error");
            }
          })
          .finally(() => {
            this.isLoadingBill = false;
            this.closeDialog = false;
          })
    },
    getCases() {
      this.$emit("getCases")
    },
  },
  computed: {}
}
</script>

<style scoped>

</style>
