<template>
  <div>
    <div v-if="files.length > 0">
      <v-card-title>
        Hochgeladene Dateien
      </v-card-title>
      <v-card-actions>
        <v-row>
          <v-col cols="auto" v-for="file in files" v-bind:key="file.id">
            <v-btn @click="download(file)"> {{ docNameSlicer(file.name) }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </div>
    <v-divider  v-if="linkedFiles.length > 0 && files.length > 0"></v-divider>
   <div v-if="linkedFiles.length > 0">
     <v-card-title>
       Dateien von verwandten Fällen
     </v-card-title>
     <v-card-actions>
       <v-row>
         <v-col cols="auto" v-for="linkedFile in linkedFiles" v-bind:key="linkedFile.id">
           <v-btn @click="download(linkedFile)"> {{ docNameSlicer(linkedFile.name) }}</v-btn>
         </v-col>
       </v-row>
     </v-card-actions>
   </div>
  </div>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {
  requestMode
} from "@/configBuilder";

export default {
  name: "FileDownloads",
  props: {
    id: {
      required: true
    },
    controllerURL: {
      required: true
    },
    dataType: {
      required: true,
    },
    dataTypeName: {
      required: true
    },
  },
  data: () => ({
    files: [],
    linkedFiles: [],
  }),
  methods: {
    docNameSlicer(docName) {
      const length = docName.length - 25;
      if (docName.length > 35) {
        return docName.substring(length)
      }
      if (docName.length > 45) {
        return docName.substring(length)
      }
      return docName
    },
    async download(doc) {
          await sessionHandler();
          this.$http.get( this.controllerURL + "Document" + "/" + doc.id, {
            responseType: "blob",
            mode: requestMode(),
            headers: {
              'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
            },
          }).then((d) => {
            let filename = doc.name;
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            const blob = new Blob([d.data], {type: doc.type || 'application/octet-stream'});
            if (typeof window.navigator.msSaveBlob !== 'undefined') {
              // IE doesn't allow using a blob object directly as link href.
              // Workaround for "HTML7007: One or more blob URLs were
              // revoked by closing the blob for which they were created.
              // These URLs will no longer resolve as the data backing
              // the URL has been freed."
              window.navigator.msSaveBlob(blob, filename);
              return;
            }
            // Other browsers
            // Create a link pointing to the ObjectURL containing the blob
            const blobURL = window.URL.createObjectURL(blob);
            const tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = blobURL;
            tempLink.setAttribute('download', filename);
            // Safari thinks _blank anchor are pop ups. We only want to set _blank
            // target if the browser does not support the HTML5 download attribute.
            // This allows you to download files in desktop safari if pop up blocking
            // is enabled.
            if (typeof tempLink.download === 'undefined') {
              tempLink.setAttribute('target', '_blank');
            }
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            setTimeout(() => {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(blobURL);
            }, 100);
          })
    },
    async getDownloads() {
          await sessionHandler();
          await this.$http
              .get(
                  this.controllerURL + "/" + "Own" + "/" + this.id + "/" + "Documents"
                  , {
                    mode: requestMode(),
                    headers: {
                      'Accept': 'application/json',
                      'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                    },
                  }
              )
              .then((response) => {
                this.files = response.data.filter(doc => {
                  return doc[ this.dataTypeName ] === this.dataType
                })
                this.linkedFiles = response.data.filter(doc => {
                  return doc[ this.dataTypeName ] !== this.dataType
                })
              })
              .catch()
    },
  },
  mounted() {
    this.getDownloads()
  }
}
</script>

<style scoped>

</style>
