<template>
  <v-card color="primary">
    <v-card-title>
      {{ unassignedCase.customerName }}
      {{ unassignedCase.customerLastname }}
      {{ unassignedCase.createdAt }}
      <v-spacer></v-spacer>
      <v-dialog
          v-model="dialog"
          width="900"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
          >
            Verwandte Fälle
          </v-btn>
        </template>


        <v-card>
          <linked-u-u-i-d-cases  v-bind:dataTypeName="dataTypeName" v-bind:caseUUID="unassignedCase.caseUUID" v-bind:controllerURL="controllerURL"></linked-u-u-i-d-cases>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click="dialog = false"
            >
              Schließen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>
    <v-card-subtitle style="padding-bottom: 0">
      {{ unassignedCase.customerMailAddress }} - {{ unassignedCase.contractNumber }}
    </v-card-subtitle>
    <v-list-item three-line>
      <v-list-item-content style="margin-top: 0">
        <v-list-item-title class="text-h5 mb-1" style="white-space: normal;">
          Fragestellung: {{ unassignedCase.notes }}
        </v-list-item-title>
        <v-list-item-subtitle>Telefonnummer: {{ unassignedCase.customerPhoneNumber }}</v-list-item-subtitle>
        <v-list-item-subtitle>Service Typ: {{ unassignedCase.serviceTypeName }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-text>
      <view-more v-bind:text="unassignedCase.record" />
    </v-card-text>
    <v-divider ></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <rebind-case v-bind:dataTypeName="dataTypeName" v-on:getCases="getCases()" v-bind:id="unassignedCase.id" v-bind:unassignedCase="unassignedCase" v-bind:controllerURL="controllerURL"  v-bind:rebindPermission="rebindPermission"></rebind-case>
    </v-card-actions>
  </v-card>
</template>

<script>
import LinkedUUIDCases from "@/components/Case/LinkedUUIDCases";
import RebindCase from "@/components/Case/RebindCase";
import ViewMore from "@/components/ViewMore";

export default {
  name: "UnassignedCaseCard",
  components: {ViewMore, LinkedUUIDCases, RebindCase},
  props: {
    unassignedCase: {
      required: true
    },
    controllerURL: {
      required: true
    },
    dataTypeName: {
      required: true
    },
    rebindPermission: {
      required: true
    },
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    getCases(){
      this.$emit("getCases")
    }
  },
  computed: {

  }
}
</script>

<style scoped>

</style>
